import React from "react";
import { useNavigate } from "react-router-dom";

function Home() {

    const navigate = useNavigate()

    const goToMLB = () => {
        navigate("/mlb");
    }

    const goToNBA = () => {
        navigate("/nba");
    }

    //const goToNFL = () => {
    //    navigate("/nfl");
    //}

    const goToAdmin = () => {
        navigate("/admin");
    }

    const goToCalculator = () => {
        navigate("/tools/calculator")
    }

    var homePage = <div>
        
        <div className="App-header-center">
            <h1>Lookie Bookie</h1>
            <div>
                {/*<button className="App-button" onClick={() => goToNFL()}>NFL</button>*/}
                <button className="App-button" onClick={() => goToNBA()}>NBA</button>
                <button className="App-button" onClick={() => goToMLB()}>MLB</button>
                <button className="App-button" onClick={() => goToAdmin()}>Admin</button>
                <button className="App-button" onClick={() => goToCalculator()}>Calculator</button>
            </div>
            </div>
        </div>


    return homePage;
}

export default Home