import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

//DEFINE PAYOUT STRUCTURES
//Underdog Payouts
const underdogPayouts = {
    flex: {
        "3Pick": { fullWin: 3.0, partialWin: 1.0, smallWin: null },
        "4Pick": { fullWin: 6.0, partialWin: 1.5, smallWin: null },
        //This small win is 0 because the kelly criterion calculator has a small win % for 5 pick flex in the prizepicks case.
        //The calculator needs to see 0 for the small win scenario to calculate the kelly criterion correctly.
        "5Pick": { fullWin: 10.0, partialWin: 2.5, smallWin: 0.0 }, 
        "6Pick": { fullWin: 25.0, partialWin: 2.6, smallWin: 0.25 }
    },
    power: {
        "2Pick": { fullWin: 3.0, partialWin: null, smallWin: null },
        "3Pick": { fullWin: 6.0, partialWin: null, smallWin: null },
        "4Pick": { fullWin: 10.0, partialWin: null, smallWin: null },
        "5Pick": { fullWin: 20.0, partialWin: null, smallWin: null },
        "6Pick": { fullWin: 35.0, partialWin: null, smallWin: null }
    }
};

//PrizePicks Payouts
const prizePicksPayouts = {
    flex: {
        "3Pick": { fullWin: 2.25, partialWin: 1.25, smallWin: 0.0 },
        "4Pick": { fullWin: 5.0, partialWin: 1.5, smallWin: null },
        "5Pick": { fullWin: 10.0, partialWin: 2.0, smallWin: 0.4 },
        "6Pick": { fullWin: 25.0, partialWin: 2.0, smallWin: 0.4 }
    },
    power: {
        "2Pick": { fullWin: 3.0, partialWin: null, smallWin: null },
        "3Pick": { fullWin: 5.0, partialWin: null, smallWin: null },
        "4Pick": { fullWin: 10.0, partialWin: null, smallWin: null },
        "5Pick": { fullWin: 20.0, partialWin: null, smallWin: null },
        "6Pick": { fullWin: 37.5, partialWin: null, smallWin: null }
    }
};

function Calculator() {
    
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate("/"); // TODO: Add navigation path
    };

    //==TEMP DATA============================================================
    const propLineData = [
        { id: 1, player: "John Doe", line: "15.5 Points", percent: 57 },
        { id: 2, player: "Jane Smith", line: "7.5 Assists", percent: 57 },
        { id: 3, player: "Mike Johnson", line: "9.5 Rebounds", percent: 57 },
        { id: 4, player: "Emily Davis", line: "2.5 Three-Pointers", percent: 57 },
        { id: 5, player: "Chris Brown", line: "22.5 Points", percent: 57 },
        { id: 6, player: "Sarah Wilson", line: "5.5 Assists", percent: 57 },
    ];
    
    const provider = "UnderDog";
    //=======================================================================

    // Set the initial payout structure to the provider received from the previous page
    const [payoutStructure, setPayoutStructure] = useState(provider);

    // State variable to track a custom payout boost. Default to 0, usually 0. Entered as %
    const [payoutBoost, setPayoutBoost] = useState(0);
    
    
    // Probability Calculator Function
    //Generate a map of all possible scenarios and their probabilities
        //EXAMPLES
        //YNNY: 0.062221
        //YNNYN: 0.02488
    function computeProbabilities(propLineData) {
        if (!propLineData || propLineData.length < 2) return {}; // Ensure at least 2 props
    
        const numProps = propLineData.length;
        const scenarios = {};
    
        // Generate probability scenarios for different subset sizes (2 to numProps)
        for (let subsetSize = 2; subsetSize <= numProps; subsetSize++) {
            generateScenariosForSubset(propLineData, subsetSize, scenarios);
        }
    
        return scenarios;
    }
    
    // Helper function to compute probability scenarios for a given subset size
    function generateScenariosForSubset(propLineData, subsetSize, scenarios) {
        const numProps = propLineData.length;
    
        // Loop through all 2^subsetSize possible scenarios
        for (let i = 0; i < (1 << subsetSize); i++) {
            let scenarioKey = "";
            let prob = 1;
            let selectedProps = 0;
    
            for (let j = 0; j < numProps; j++) {
                if (selectedProps >= subsetSize) break; // Stop once we've selected enough props
    
                if (i & (1 << selectedProps)) { // If bit is set (event occurs)
                    scenarioKey += "Y";
                    prob *= propLineData[j].percent / 100;
                } else { // If bit is not set (event does not occur)
                    scenarioKey += "N";
                    prob *= (1 - propLineData[j].percent / 100);
                }
    
                selectedProps++;
            }
    
            scenarios[scenarioKey] = prob; // Store probability for this scenario
        }
    }
        
    const probabilityScenarios = computeProbabilities(propLineData);
    
    const numProps = propLineData.length;

     //Calculate Summed probabilities for X of Y correct picks
    const summaryProbabilities = {};

    if (numProps >= 2) {
        summaryProbabilities["2-pick"] = {
            "2 of 2": probabilityScenarios["YY"] || 0
        };
    }

    if (numProps >= 3) {
        summaryProbabilities["3-pick"] = {
            "3 of 3": probabilityScenarios["YYY"] || 0,
            "2 of 3":
                (probabilityScenarios["YYN"] || 0) +
                (probabilityScenarios["YNY"] || 0) +
                (probabilityScenarios["NYY"] || 0)
        };
    }

    if (numProps >= 4) {
        summaryProbabilities["4-pick"] = {
            "4 of 4": probabilityScenarios["YYYY"] || 0,
            "3 of 4":
                (probabilityScenarios["YYYN"] || 0) +
                (probabilityScenarios["YYNY"] || 0) +
                (probabilityScenarios["YNYY"] || 0) +
                (probabilityScenarios["NYYY"] || 0)
        };
    }

    if (numProps >= 5) {
        summaryProbabilities["5-pick"] = {
            "5 of 5": probabilityScenarios["YYYYY"] || 0,
            "4 of 5":
                (probabilityScenarios["YYYYN"] || 0) +
                (probabilityScenarios["YYYNY"] || 0) +
                (probabilityScenarios["YYNYY"] || 0) +
                (probabilityScenarios["YNYYY"] || 0) +
                (probabilityScenarios["NYYYY"] || 0),
            "3 of 5":
                (probabilityScenarios["NNYYY"] || 0) +
                (probabilityScenarios["NYNYY"] || 0) +
                (probabilityScenarios["NYYNY"] || 0) +
                (probabilityScenarios["NYYYN"] || 0) +
                (probabilityScenarios["YNNYY"] || 0) +
                (probabilityScenarios["YNYNY"] || 0) +
                (probabilityScenarios["YNYYN"] || 0) +
                (probabilityScenarios["YYNNY"] || 0) +
                (probabilityScenarios["YYNYN"] || 0) +
                (probabilityScenarios["YYYNN"] || 0)
        };
    }

    if (numProps >= 6) {
        summaryProbabilities["6-pick"] = {
            "6 of 6": probabilityScenarios["YYYYYY"] || 0,
            "5 of 6":
                (probabilityScenarios["YYYYYN"] || 0) +
                (probabilityScenarios["YYYYNY"] || 0) +
                (probabilityScenarios["YYYNYY"] || 0) +
                (probabilityScenarios["YYNYYY"] || 0) +
                (probabilityScenarios["YNYYYY"] || 0) +
                (probabilityScenarios["NYYYYY"] || 0),
            "4 of 6":
                (probabilityScenarios["NNYYYY"] || 0) +
                (probabilityScenarios["NYNYYY"] || 0) +
                (probabilityScenarios["NYYNYY"] || 0) +
                (probabilityScenarios["NYYYNY"] || 0) +
                (probabilityScenarios["NYYYYN"] || 0) +
                (probabilityScenarios["YNNYYY"] || 0) +
                (probabilityScenarios["YNYNYY"] || 0) +
                (probabilityScenarios["YNYYNY"] || 0) +
                (probabilityScenarios["YNYYYN"] || 0) +
                (probabilityScenarios["YYNNYY"] || 0) +
                (probabilityScenarios["YYNYNY"] || 0) +
                (probabilityScenarios["YYNYYN"] || 0) +
                (probabilityScenarios["YYYNYN"] || 0) +
                (probabilityScenarios["YYYNNY"] || 0) +
                (probabilityScenarios["YYYYNN"] || 0)
        };
    }

    //Calculate Expected Values for each entry type
    //Uses Entry type, # picks, win probabilties, payout data, and payout boost
    const generateEntryProbabilities = (summaryProbabilities) => {
    
        const calculateEV = (entryType, numPicks, fullWinProb, partialWinProb, smallWinProb, payoutData, payoutBoost) => {
            const category = entryType.includes("Flex") ? "flex" : "power";
            const payouts = payoutData[category][`${numPicks}Pick`];
        
            if (!payouts) return 0; // Fallback if payout data is missing
        
            // Factor in boost, if applicable
            const boostMultiplier = 1 + ((parseFloat(payoutBoost) || NaN) / 100 || 0);
        
            return (
                (fullWinProb * ((payouts.fullWin || 0) * boostMultiplier)) +
                (partialWinProb * ((payouts.partialWin || 0) * boostMultiplier)) +
                (smallWinProb * ((payouts.smallWin || 0) * boostMultiplier))
            );
        };
    
        return Object.fromEntries(
            Object.entries({
                "2PickPower": { minPropsRequired: 2 },
                "3PickPower": { minPropsRequired: 3 },
                "4PickPower": { minPropsRequired: 4 },
                "5PickPower": { minPropsRequired: 5 },
                "6PickPower": { minPropsRequired: 6 },
                "3PickFlex": { minPropsRequired: 3 },
                "4PickFlex": { minPropsRequired: 4 },
                "5PickFlex": { minPropsRequired: 5 },
                "6PickFlex": { minPropsRequired: 6 }
            }).map(([entryType, { minPropsRequired }]) => {
                const numPicks = parseInt(entryType[0]); // Extracts number from "3PickPower" -> 3
                const probKey = `${numPicks}-pick`;
    
                const category = entryType.includes("Flex") ? "flex" : "power";
                const fullWinProb = summaryProbabilities[probKey]?.[`${numPicks} of ${numPicks}`] || 0;
                const partialWinProb = category === "power" ? 0 : (summaryProbabilities[probKey]?.[`${numPicks - 1} of ${numPicks}`] || 0);
                const smallWinProb = category === "power" ? 0 : (summaryProbabilities[probKey]?.[`${numPicks - 2} of ${numPicks}`] || 0);
    
                return [
                    entryType,
                    {
                        displayName: entryType.replace(/(\d+)Pick(\w+)/, "$1 $2"),      //6 Flex, 3 Power, etc
                        fullWinProb,
                        partialWinProb,
                        smallWinProb,
                        minPropsRequired,
                        underdogEV: calculateEV(entryType, numPicks, fullWinProb, partialWinProb, smallWinProb, underdogPayouts, payoutBoost),
                        prizePicksEV: calculateEV(entryType, numPicks, fullWinProb, partialWinProb, smallWinProb, prizePicksPayouts, payoutBoost )
                    }
                ];
            })
        );
    };
    
    const entryProbabilities = generateEntryProbabilities(summaryProbabilities);
    
    //Render the main display for Calculator()
    //Title, Selected Props Table, Payout Structure Dropdown, Payout Boost Input
    return (

        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "70%", margin: "0 auto 20px" }}>
            <h2>Calculator</h2>
            <button 
                    className="App-button" 
                    onClick={navigateBack} 
                    style={{ marginLeft: "10px", padding: "8px 12px", fontSize: "16px" }}>
                    Back to Home
                </button> 
               </div>
            {/* Table of Provided Selected Props */}
            <table
                style={{
                    width: "70%", 
                    margin: "20px auto", 
                    textAlign: "center", 
                    borderCollapse: "separate",
                    borderSpacing: "0 1px",
                    border: "2px solid white",
                    fontSize: "20px" 
                }}
            >
                <thead style={{ backgroundColor: "#16052b", color: "white" }}>
                    <tr>
                        {["#", "Player", "Line", "%"].map((header, index) => (
                            <th
                                key={index}
                                style={{
                                    padding: "5px 20px",
                                    textAlign: index === 1 || index === 2 ? "left" : "center" // Align Player & Line headers to the left
                                }}
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {propLineData.map((row) => (
                        <tr
                            key={row.id}
                            style={{
                                backgroundColor: "black",
                                color: "white",
                                height: "35px"
                            }}
                        >
                            {[row.id, row.player, row.line, row.percent].map((cell, index) => (
                                <td
                                    key={index}
                                    style={{
                                        padding: "5px 20px",
                                        textAlign: index === 1 || index === 2 ? "left" : "center" // Align Player & Line to the left
                                    }}
                                >
                                    {cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <hr style={{ width: "80%", margin: "20px auto", border: "1px solid white" }} />
    
            {/*Payout Structure & Payout Boost*/}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "100px", marginBottom: "20px" }}>
                
                {/* Payout Structure Dropdown */}
                <div>
                    <label><b>Payout Structure</b></label><br />
                    <select 
                        value={payoutStructure} 
                        onChange={(e) => setPayoutStructure(e.target.value)}
                        style={{ padding: "5px", fontSize: "16px", marginTop: "5px" }}
                    >
                        <option value="UnderDog">UnderDog</option>
                        <option value="PrizePicks">PrizePicks</option>
                    </select>
                </div>
    
                {/*Payout Boost Input*/}
                <div>
                    <label><b>Payout Boost (%)</b></label><br />
                    <input 
                        type="number" 
                        value={payoutBoost}
                        onChange={(e) => setPayoutBoost(e.target.value)}
                        placeholder="Enter boost %" 
                        style={{ 
                            padding: "5px", 
                            fontSize: "16px", 
                            marginTop: "5px", 
                            textAlign: "center", 
                            width: "100px" 
                        }}
                    />
                </div>
            </div>
            <hr style={{ width: "80%", margin: "20px auto", border: "1px solid white" }} />
            {/* End of Main Display */}

            {/* Render the table component. Pass data into it */}
            <ParlayTable 
                payoutStructure={payoutStructure} 
                payoutBoost={payoutBoost} 
                entryProbabilities={entryProbabilities}
                numEntries={propLineData.length}
            />
        </div>
    );   
}


//Create table to show EV and % win for each entry type
function ParlayTable({ payoutStructure, payoutBoost, entryProbabilities, numEntries }) {
    const hideUnderdog = payoutStructure === "PrizePicks";
    const hidePrizePicks = payoutStructure === "UnderDog";
    const evKey = hideUnderdog ? "prizePicksEV" : "underdogEV";

    const [selectedRow, setSelectedRow] = useState(null); // Track the selected row

    // Kelly Criterion Calculator
    const calculateKellyCriterion = (matCurr) => {
        const valThreshold = 0.000001;  // Convergence threshold
        const valInitialValue = 0.25;   // Starting guess for Newton's method
    
        // Normalize probabilities to sum to 1
        const totalProb = matCurr.reduce((sum, [, prob]) => sum + prob, 0);
        if (totalProb !== 1) {
            matCurr = matCurr.map(([b, p]) => [b, p / totalProb]);
        }
    
        // Check if the matrix is even profitable
        const profitabilityCheck = matCurr.reduce((sum, [b, p]) => sum + b * p, 0);
        if (profitabilityCheck <= 0) {
            return 0; // Not profitable
        }
    
        let valPast = 0;
        let valNext = valInitialValue;
        let boolBump = false;
    
        while (Math.abs(valPast - valNext) > valThreshold) {
            valPast = valNext;
    
            let numerator = 0;
            let denominator = 0;
    
            for (const [b, p] of matCurr) {
                numerator += (p * b) / (1 + b * valPast);
                denominator += (-b * b * p) / ((1 + b * valPast) ** 2); // ✅ Wrapped in parentheses
            }
    
            valNext = valPast - numerator / denominator;
    
            if (valNext < 0 && !boolBump) {
                valNext = 0;
                boolBump = true; // Ensure we only bump once
            }
        }
    
        return valNext; // Kelly Criterion Optimal Bet Fraction
    };

    //Display for the Parlay Table
    return (
        <div style={{ textAlign: "center", color: "white" }}>
            <h3>Entry Probabilities & Expected Values</h3>

            <p style={{ fontSize: "14px", color: "#ccc", marginTop: "-10px", marginBottom: "20px" }}>
                Provider:{" "}
                <strong style={{ color: payoutStructure === "UnderDog" ? "#ffce00" : payoutStructure === "PrizePicks" ? "pink" : "#ccc" }}>
                    {payoutStructure}
                </strong>{" "}
                | # Props: <strong>{numEntries}</strong> | Boost: <strong>{payoutBoost}%</strong>
            </p>

            <table style={{ width: "100%", textAlign: "left", borderCollapse: "separate", borderSpacing: "0 0px", border: "3px solid black", marginTop: "20px", fontSize: "20px" }}>
                <thead style={{ backgroundColor: "#16052b", color: "white" }}>
                    <tr>
                        <th style={{ padding: "5px 15px" }}></th> {/* Empty header for radio buttons */}
                        <th style={{ padding: "5px 30px" }}>Entry Type</th>
                        {!hideUnderdog && <th style={{ padding: "5px 30px" }}>EV</th>}
                        {!hidePrizePicks && <th style={{ padding: "5px 30px" }}>EV</th>}
                        <th style={{ padding: "5px 30px" }}>Full Win %</th>
                        <th style={{ padding: "5px 30px" }}>Partial Win %</th>
                        <th style={{ padding: "5px 30px" }}>Small Win %</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(entryProbabilities)
                        .filter(([entryType]) => parseInt(entryType[0]) <= numEntries)
                        .sort(([, dataA], [, dataB]) => dataB[evKey] - dataA[evKey]) // Sort by selected EV field
                        .map(([entryType, data]) => {
                            const evValue = payoutStructure === "UnderDog" ? data.underdogEV : data.prizePicksEV;

                            let bgColor = "#a61c00"; // Default Dark Red
                            if (evValue > 1.12) bgColor = "#6aa84f"; // Solid Green
                            else if (evValue > 1.08) bgColor = "#b6d7a8"; // Light Green
                            else if (evValue > 1.03) bgColor = "#d9ead3"; // Pale Green
                            else if (evValue > 1.00) bgColor = "#ffe599"; // Pale Yellow
                            else if (evValue > 0.98) bgColor = "#e6b8af"; // Rose
                            else if (evValue > 0.95) bgColor = "#dd7e6b"; // Light Red

                            return (
                                <tr key={entryType} style={{ backgroundColor: bgColor, color: "black", height: "40px" }}>
                                    <td style={{ padding: "5px 15px", textAlign: "center" }}>
                                        <input
                                            type="radio"
                                            name="entrySelection"
                                            checked={selectedRow === entryType}
                                            onChange={() => setSelectedRow(entryType)}
                                        />
                                    </td>
                                    <td style={{ padding: "5px 30px" }}>{data.displayName}</td>
                                    {!hideUnderdog && <td style={{ padding: "5px 30px" }}>{data.underdogEV.toFixed(3)}</td>}
                                    {!hidePrizePicks && <td style={{ padding: "5px 30px" }}>{data.prizePicksEV.toFixed(3)}</td>}
                                    <td style={{ padding: "5px 30px" }}>{(data.fullWinProb * 100).toFixed(1)}%</td>
                                    <td style={{ padding: "5px 30px" }}>{data.partialWinProb > 0 ? `${(data.partialWinProb * 100).toFixed(1)}%` : ""}</td>
                                    {/* Hide the small win % if the entry is a Ud 5 pick flex. Only PP has a 5 flex payout. */}
                                    <td style={{ padding: "5px 30px" }}>
                                        {!(payoutStructure === "UnderDog" && entryType === "5PickFlex") && data.smallWinProb > 0
                                            ? `${(data.smallWinProb * 100).toFixed(1)}%`
                                            : ""}
                                    </td>

                                </tr>
                            );
                        })}
                </tbody>
            </table>
            
            <div>
            {/* Show Win % Probabilities */}
            {selectedRow && (() => {
                const roundToOne = (num) => (num * 100).toFixed(1); // Convert to percentage and round to 1 decimal

                const fullWinProb = roundToOne(entryProbabilities[selectedRow].fullWinProb);
                const partialWinProb = entryProbabilities[selectedRow].partialWinProb 
                    ? roundToOne(entryProbabilities[selectedRow].partialWinProb) 
                    : null;
                const smallWinProb = entryProbabilities[selectedRow].smallWinProb 
                    ? roundToOne(entryProbabilities[selectedRow].smallWinProb) 
                    : null;
                const noWinProb = roundToOne(1 - (entryProbabilities[selectedRow].fullWinProb + 
                                                (entryProbabilities[selectedRow].partialWinProb || 0) + 
                                                (entryProbabilities[selectedRow].smallWinProb || 0)));

                const probabilityParts = [`Full: ${fullWinProb}%`]; // Always include Full Win Probability
                if (partialWinProb !== null) probabilityParts.push(`Partial: ${partialWinProb}%`);
                if (smallWinProb !== null) probabilityParts.push(`Small: ${smallWinProb}%`);
                probabilityParts.push(`Loss: ${noWinProb}%`); // Always include No Win Probability

                return (
                    <div style={{ 
                        marginTop: "3px", 
                        padding: "1px", 
                        borderRadius: "3px", 
                        display: "inline-block", 
                        textAlign: "center",
                        color: "#ccc",
                        fontSize: "16px"
                    }}>
                        <p>{probabilityParts.join(" | ")}</p>
                    </div>
                );
            })()}
            </div>


            <div>
            {/* Show Payout Multipliers */}
            {selectedRow && (() => {
                const payoutModel = payoutStructure === "UnderDog" ? underdogPayouts : prizePicksPayouts;
                const isPower = selectedRow.includes("Power"); // Check if it's a "Power" entry
                const entryType = selectedRow.replace(/(Power|Flex)$/, ""); // Extract base entry type

                const payoutData = isPower ? payoutModel.power[entryType] : payoutModel.flex[entryType];

                if (!payoutData) return <p style={{ color: "red" }}>⚠️ No payout data available.</p>;

                const boostedFullWin = (payoutData.fullWin * (1+payoutBoost/100)).toFixed(2);
                const boostedPartialWin = payoutData.partialWin !== null ? (payoutData.partialWin * (1+payoutBoost/100)).toFixed(2) : null;
                const boostedSmallWin = payoutData.smallWin !== null ? (payoutData.smallWin * (1+payoutBoost/100)).toFixed(2) : null;

                const payoutParts = [`${boostedFullWin}x`]; // Always include Full Win Payout
                if (boostedPartialWin !== null) payoutParts.push(`${boostedPartialWin}x`);
                if (boostedSmallWin !== null) payoutParts.push(`${boostedSmallWin}x`);

                return (
                    <div style={{ 
                        marginTop: "3px", 
                        padding: "1px", 
                        borderRadius: "3px", 
                        display: "inline-block", 
                        textAlign: "center",
                        color: "#ccc",
                        fontSize: "16px"
                    }}>
                        <p>Payouts: {payoutParts.join(" | ")}</p>
                    </div>
                );
            })()}
            <hr style={{ width: "80%", margin: "20px auto", border: "1px solid white" }} />
            </div>
            
            {selectedRow && (() => {
            const payoutModel = payoutStructure === "UnderDog" ? underdogPayouts : prizePicksPayouts;
            const isPower = selectedRow.includes("Power"); // Check if it's a "Power" entry
            const entryType = selectedRow.replace(/(Power|Flex)$/, ""); // Extract base entry type
            const payoutData = isPower ? payoutModel.power[entryType] : payoutModel.flex[entryType];

            if (!payoutData) return <p style={{ color: "red" }}>⚠️ No payout data available.</p>;

            const roundToThree = (num) => Math.round(num * 1000) / 1000;

            // Win probabilities
            const fullWinProb = roundToThree(entryProbabilities[selectedRow].fullWinProb);
            const partialWinProb = roundToThree(entryProbabilities[selectedRow].partialWinProb || 0);
            const smallWinProb = roundToThree(entryProbabilities[selectedRow].smallWinProb || 0);
            const noWinProb = roundToThree(1 - (fullWinProb + partialWinProb + smallWinProb));

            // Boosted payouts
            const boostedFullWin = roundToThree((payoutData.fullWin * (1 + payoutBoost / 100)) - 1);
            const boostedPartialWin = payoutData.partialWin !== null 
                ? roundToThree((payoutData.partialWin * (1 + payoutBoost / 100)) - 1) 
                : null;
            const boostedSmallWin = payoutData.smallWin !== null 
                ? roundToThree((payoutData.smallWin * (1 + payoutBoost / 100)) - 1) 
                : null;

            // Construct matCurr
            const matCurr = [
                [-1, noWinProb],
                [boostedFullWin, fullWinProb]
            ];
            if (boostedPartialWin !== null) matCurr.push([boostedPartialWin, partialWinProb]);
            if (boostedSmallWin !== null) matCurr.push([boostedSmallWin, smallWinProb]);

            // Compute Bankroll Fraction using Kelly Criterion formula
            const kellyFraction = calculateKellyCriterion(matCurr);

            
            // Kelly Criterion Display
            const KellyDisplay = ({ kellyFraction }) => {
                const [bankroll, setBankroll] = useState(""); // Track user input
                const [betAmount, setBetAmount] = useState(null); // Track calculated bet size
            
                // Function to handle user input and calculate suggested wager
                const handleBankrollChange = (event) => {
                    const value = event.target.value;
                    setBankroll(value);
            
                    // Convert to a number and calculate recommended wager
                    const numericBankroll = parseFloat(value);
                    if (!isNaN(numericBankroll)) {
                        setBetAmount((numericBankroll * kellyFraction).toFixed(2));
                    } else {
                        setBetAmount(null);
                    }
                };
            
                return (
                    <div 
                        style={{ 
                            marginTop: "20px", 
                            display: "flex", 
                            alignItems: "center",  // Ensure vertical centering
                            justifyContent: "center",
                            gap: "30px"  // Space between title and content
                        }}
                    >
                        <h2 style={{ 
                        color: "#ddd", 
                        height: "150px",  // Set a specific height
                        lineHeight: "50px",  // Ensures text is vertically centered
                        margin: 0,  // Remove extra spacing
                        whiteSpace: "nowrap", // Prevents text wrapping
                        paddingRight: "10px" // Adjust space between title and table
                    }}>
                        Suggested Wager
                    </h2>
            
                        {/* Right-Side Content (Table, Input, Link) */}
                        <div style={{ textAlign: "center" }}>
                            <table style={{ 
                                width: "100%", 
                                borderCollapse: "collapse", 
                                border: "1px solid #ddd",
                                fontSize: "18px",
                                marginBottom: "10px"
                            }}>
                                <thead>
                                    <tr style={{ backgroundColor: "#333", color: "#fff" }}>
                                        <th style={{ padding: "10px", border: "1px solid #ddd" }}>Bankroll %</th>
                                        <th style={{ padding: "10px 20px", border: "1px solid #ddd" }}>$</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ backgroundColor: "#222", color: "#ddd" }}>
                                        <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                                            {(kellyFraction * 100).toFixed(2)}%
                                        </td>
                                        <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                                            {betAmount !== null ? `$${betAmount}` : "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
            
                            {/* User Input */}
                            <div style={{ marginBottom: "10px" }}>
                                <label style={{ color: "#ddd", fontSize: "16px" }}>Bankroll: </label>
                                <input
                                    type="number"
                                    value={bankroll}
                                    onChange={handleBankrollChange}
                                    placeholder="$"
                                    style={{
                                        marginLeft: "10px",
                                        padding: "5px",
                                        fontSize: "16px",
                                        textAlign: "center",
                                        width: "100px",
                                    }}
                                />
                            </div>
            
                            {/* Hyperlink */}
                            <a 
                                href="https://math.stackexchange.com/questions/662104/kelly-criterion-with-more-than-two-outcomes" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                style={{ 
                                    color: "#007bff", 
                                    textDecoration: "underline", 
                                    fontSize: "14px", 
                                    display: "block", 
                                    marginBottom: "20px" 
                                }}
                            >
                                Calculation Details
                            </a>
                        </div>
                    </div>
                );
            };
            return <KellyDisplay kellyFraction={kellyFraction} />;
        })()}  
        </div>
    );
}

export default Calculator;
